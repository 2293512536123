<template>
  <div class="wengao container">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <Advertisement :needTitle="false" :pos="'mwxJTW'" :path="path" :id="id" />
    <Article :article="article" v-if="canShow" />
    <OpenApp :path="path" :id="id" />
    <Advertisement :pos="'qFOTY5'" />
    <Tuijian :title="tujianTitle" :list="tuijianList" :path="path" :id="id" />
    <Comment :list="commentList" v-show="article.can_comment == 2 && commentList.length > 0" :path="path" :id="id" />
    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import Advertisement from "@/components/advertisement";
  import Tuijian from "@/components/tuijian";
  import OpenApp2 from "@/components/openApp2";
  import OpenApp from "@/components/openApp";
  import linkApp from "@/utils/linkApp";
  import Article from "@/components/article";
  import Comment from "@/components/comment";
  import { getArticleDetail, getCommit, getTuijian } from "@/utils/api";
  import { wxShare, init } from "@/utils/wxShare";
  export default {
    name: "wengao",
    components: {
      Tanchuang,
      Kcz,
      Advertisement,
      Tuijian,
      OpenApp2,
      OpenApp,
      Article,
      Comment,
    },
    data() {
      return {
        canShow: false,
        id: "",
        path: "kczDetail",
        adver: {
          title: "",
          src: "",
        },
        adver2: {
          title: "",
          src: "",
        },
        article: {
          title: "",
          author: "",
          source: "",
          time: "",
          readNum: "",
          content: "",
        },
        share_config: {},
        tuijianList: [],
        tujianTitle: "推荐阅读",
        commentList: [],
      };
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      async getDetail(id) {
        const res = await getArticleDetail(id);
        const data = res.data.data;
        this.article.title = data.title;
        this.article.author = data.author;
        this.article.source = data.source_from;
        this.article.time = data.published_at;
        this.article.readNum = data.views;
        this.article.content = data.content;
        this.article.can_comment = data.can_comment;
        this.share_config = data.share_config;
        document.title = data.title;
        this.canShow = true;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
      async getCommit(id) {
        const res = await getCommit(id);
        const data = res.data.data;
        this.commentList = data.slice(0, 3).map(val => {
          return {
            avatar: val.avatar,
            name: val.nickname,
            time: val.created_at_human,
            like: val.likes_count,
            content: val.content,
            show_comment: val.show_comment,
          };
        });
      },
      async getTuijian(id) {
        const res = await getTuijian(id);
        const data = res.data.data;
        this.tuijianList = data.map(val => {
          return {
            content: val.title,
            source: val.source_from,
            src: val.share_config.share_img,
          };
        });
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getDetail(this.id);
      this.getCommit(this.id);
      this.getTuijian(this.id);
    },
    mounted() {
      function resizeIFrameToFitContent(iFrame) {
        iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
        iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
      }

      window.addEventListener("DOMContentLoaded", function(e) {
        // or, to resize all iframes:
        var iframes = document.querySelectorAll("iframe");
        for (var i = 0; i < iframes.length; i++) {
          resizeIFrameToFitContent(iframes[i]);
        }
      });
      setTimeout(() => {}, 1000);
    },
  };
</script>

<style lang="scss"></style>
